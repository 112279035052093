import React from "react"
import PropTypes from "prop-types"

// Components
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`
  return (
    <div>
      <Layout location={location} title={tagHeader}>
        <Seo title="Tags" />
        <ol style={{ listStyle: `none` }}>
          {edges.map(({ node }) => {
            const { slug } = node.fields
            const { excerpt } = node
            const { featuredimage } = node.frontmatter
            const title = node.frontmatter.title || node.fields.slug
            return (
              <li key={slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2>
                      <Link to={slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <small>{node.frontmatter.date}</small>
                  </header>
                  {featuredimage && (
                    <Img
                      class="gatsby-resp-image-image"
                      fluid={featuredimage.src.childImageSharp.fluid}
                      alt={featuredimage.alt}
                      style={{
                        width: "100%",
                        height: "100%",
                        margin: "0px",
                        // verticalAlign: "middle",
                        // position: "absolute",
                        // top: "0px",
                        // left: "0px",
                        opacity: 1,
                        transition: "opacity 0.5s ease 0s",
                        color: "inherit",
                        boxShadow: "white 0px 0px 0px 400px inset",
                      }}
                    />
                  )}
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        // __html: node.frontmatter.description || excerpt,
                        __html: excerpt,
                      }}
                      itemProp="description"
                    />
                    <a href={slug}>Read more</a>
                  </section>
                </article>
              </li>
            )
          })}
        </ol>
        {/*
            This links to a page that does not yet exist.
            You'll come back to it!
          */}
        <Link to="/tags">All tags</Link>
      </Layout>
    </div>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            featuredimage {
              src {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  }
`
